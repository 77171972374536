import Lenis from "@studio-freight/lenis";
import { useEffect } from "react";

import type { ReactElement } from "react";

type LenisScrollProps = {
  children: ReactElement;
};
export function LenisScroller({ children }: LenisScrollProps): ReactElement {
  useEffect(() => {
    const lenis = new Lenis({
      duration: 1,
      smoothTouch: true,
    });

    function raf(time: number) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    return () => {
      lenis.destroy();
    };
  }, []);

  return children;
}
