import Script from "next/script";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

import type { ReactElement, ReactNode } from "react";

type ContextValue = {
  Player: PlayerClass | null;
  PlayerState: Record<string, number> | null;
};

const Context = createContext<ContextValue>({
  Player: null,
  PlayerState: null,
});

type YTContextProps = {
  children: ReactNode;
};

export function useYtContext(): ContextValue {
  return useContext(Context);
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface PlayerClass extends Function {
  new(
    id: string,
    options: {
      videoId: string;
      playerVars: Record<string, number>;
      events: {
        onReady: (event: any) => void;
        onStateChange: (event: {
          data: number;
          target: { playVideo(): void };
        }) => void;
      };
    },
  ): any;
}

declare const global: typeof globalThis & {
  YT: { Player: PlayerClass; PlayerState: Record<string, number> } | undefined;
  onYouTubeIframeAPIReady: () => void;
};

export function YTContext({ children }: YTContextProps): ReactElement {
  const [{ Player, PlayerState }, setProvider] = useState<ContextValue>({
    Player: null,
    PlayerState: null,
  });

  const contextValue = useMemo(
    () => ({
      Player,
      PlayerState,
    }),
    [Player, PlayerState],
  );

  useEffect(() => {
    if (!Player) {
      global.onYouTubeIframeAPIReady = () => {
        if (global.YT) {
          setProvider(global.YT);
        }
      };
    }
  }, [Player]);

  return (
    <>
      <Script defer src="https://www.youtube.com/iframe_api" id="yt" />
      <Context.Provider value={contextValue}>{children}</Context.Provider>
    </>
  );
}
