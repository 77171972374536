import 'keen-slider/keen-slider.min.css'
import '../styles/globals.css'
import { css, Global, getHomeUrl } from '@obvio/app'
import { app } from '@obvio/app/App/index'

import { LenisScroller } from '@/components/LenisProvider'
import { dmSans, factor, writer } from '@/constants/fonts'
import { YTContext } from '@/contexts/YTContext'

export default app({
  contextPaths: {
    articles: '/blog',
  },

  seoData: {
    title: 'Golf Mała Wieś',
    canonical: getHomeUrl(),
    description:
      'Idealne miejsce treningowe dla wielbicieli golfa położone w malowniczej Małej Wsi pod Warszawą',
    openGraph: {
      type: 'website',
      title: 'Golf Mała Wieś',
      description:
        'Idealne miejsce treningowe dla wielbicieli golfa położone w malowniczej Małej Wsi pod Warszawą',
      images: [
        {
          url: `https://www.golfmalawies.pl/static/images/golf.webp`,
          width: 1800,
          height: 1200,
          type: `image/webp`,
          alt: 'Golf',
        },
      ],
    },
  },

  enhance: (C) => (p) =>
    (
      <>
        <YTContext>
          <LenisScroller>
            <C {...p} />
          </LenisScroller>
        </YTContext>
        <Global
          styles={css`
            :root {
              --font-family-factor: ${factor.style.fontFamily};
              --font-family-writer: ${writer.style.fontFamily};
              --font-family-dmSans: ${dmSans.style.fontFamily};
            }
          `}
        />
      </>
    ),
})
